import React, { useState, useEffect, useContext } from "react";
import Button from "../components/Button";
import PersonIcon from "@mui/icons-material/Person";
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import { useNavigate, useLocation } from "react-router-dom";

const Header = ({ handleOpenMyGarden, setIsLoginOpen, user, type }) => {
  const [windowsWidth, setWindowsWidth] = useState(window.innerWidth);
  const isMobile = () => windowsWidth < 580;
  const handleResize = (width) => setWindowsWidth(width);

  useEffect(() => {
    window.addEventListener("resize", (e) => handleResize(window.innerWidth));
  });

  const history = useNavigate();
  const location = useLocation();

  return (
    <div className="process_topHeader">
      <div onClick={() => history("/")} className="process_topHeader_left">
        <img src="logo.png" /> <span>Petite Carotte</span>
      </div>
      <div className="process_topHeader_center"></div>
      <div className="process_topHeader_right">
        <ul>
          <li
            className={location.pathname === "/faq" ? "active" : ""}
            onClick={() => history(`/faq`)}
          >
            F.A.Q
          </li>

          {user && type ? (
            <li onClick={() => handleOpenMyGarden()}>Mon compte</li>
          ) : (
            type && (
              <li onClick={() => setIsLoginOpen(true)}>
                Connexion / Inscription
              </li>
            )
          )}
        </ul>
        <Button
          color="white"
          size="small"
          value={"La map"}
          onCallback={() => history("/")}
          startIcon={<TravelExploreOutlinedIcon />}
          classname={""}
        />
      </div>
    </div>
  );
};

export default Header;
