import React, { useState, useEffect, useContext, useRef } from "react";
import "../styles/Components.scss";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import Button from "../components/Button";
import AddIcon from "@mui/icons-material/Add";
import Dropdown_Catalogue from "./Dropdown_Catalogue";
import { AuthContext } from "../utils/context";

const initial_Product = {
  name: "",
  type: "",
  quantity: "0-10",
  product_ID: "",
  harvest_at: new Date(),
  variety: [],
  _id: "",
};

const Catalogue = ({ onCallback, isOpen }) => {
  const [products, setProducts] = useState([]);

  const [editingProduct, setEditingProduct] = useState(initial_Product);
  const [isCreateOpened, setIsCreateOpened] = useState(false);
  const [isEditOpened, setIsEditOpened] = useState(false);
  const [creatingProduct, setCreatingProduct] = useState(initial_Product);
  const [errorField, setErrorField] = useState("");
  const { API_URL, user, setUser } = useContext(AuthContext);
  const token = localStorage.getItem("auth");

  // const { user, setUser, userProductsKOC, setUserProductsKOC } =
  //   useAndGetContext();
  const containerRef = useRef(null);

  // const firebase = useFirebase();
  // const Firestore = firebase.firestore();

  // const DBusersRef = Firestore.collection("users");
  // const DBproductsRef = Firestore.collection("products");

  useEffect(() => {
    fetch(`${API_URL}/products/${user._id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    }).then((res) =>
      res.json().then((res) => {
        setProducts(res);
      })
    );
    // if (userProductsKOC.length === 0) {
    //   DBusersRef.doc(user.uid)
    //     .collection("products")
    //     .get()
    //     .then((res) => {
    //       let newProducts = [];
    //       res.forEach((item) => {
    //         const data = item.data();
    //         newProducts = [
    //           ...newProducts,
    //           {
    //             name: data.name,
    //             type: data.type,
    //             quantity: data.quantity,
    //             product_id: data.product_id,
    //             harvest_at: new Date(data.harvest_at.seconds * 1000),
    //             variety: data.variety,
    //             id: item.id,
    //           },
    //         ];
    //         setProducts(newProducts);
    //         setUserProductsKOC(newProducts)
    //       });
    //     });
    // }
    // else setProducts(userProductsKOC)
    // DBproductsRef.get().then((res) => {
    //   let newBaseProducts = [];
    //   res.forEach((item) => {
    //     const data = item.data();
    //     newBaseProducts = [
    //       ...newBaseProducts,
    //       { name: data.name, type: data.type, id: item.id },
    //     ];
    //   });
    //   setBaseProducts(newBaseProducts);
    // });
  }, []);

  useEffect(() => {
    setIsEditOpened(false);
    setIsCreateOpened(false);
    setCreatingProduct(initial_Product);
    setEditingProduct(initial_Product);
  }, [isOpen]);

  async function handleSaveCreatingProduct() {
    if (creatingProduct.product_ID.length < 2) {
      setErrorField("product");
      setTimeout(() => {
        setErrorField("");
      }, 3000);
      return;
    }
    try {
      // const productIds =
      //   user.products_ids === undefined ? [] : [...user.products_ids];
      // const newProductsIds = [...productIds, creatingProduct.product_ID];

      let { _id, name, type, ...newProduct } = creatingProduct;
      newProduct.user_ID = user._id;

      const req = await fetch(`${API_URL}/products/add_to_catalogue`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          token: `Bearer ${token}`,
        },
        body: JSON.stringify({
          newProduct: newProduct,
        }),
      });
      const res = await req.json();
      if (res.quantity) {
        setProducts([{ ...res, _id: res._id }, ...products]);

        setCreatingProduct(initial_Product);
        setErrorField("");
        setIsCreateOpened(false);
      }

      // await DBusersRef.doc(user.uid).update({ products_ids: newProductsIds });
      // const req = await DBusersRef.doc(user.uid)
      //   .collection("products")
      //   .add(creatingProduct);
      // setCreatingProduct(initial_Product);
      // setProducts([{ ...creatingProduct, id: req.id }, ...products]);
      // setUserProductsKOC([{ ...creatingProduct, id: req.id }, ...products]);
      // setErrorField("");
      // setIsCreateOpened(false);
      // setUser({ ...user, products_ids: newProductsIds });
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSaveEditingProduct() {
    if (editingProduct.product_ID.length < 2) {
      setErrorField("product");
      setTimeout(() => {
        setErrorField("");
      }, 3000);
      return;
    }
    try {
      const req = await fetch(`${API_URL}/products/${editingProduct._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          token: `Bearer ${token}`,
        },
        body: JSON.stringify({
          quantity: editingProduct.quantity,
          variety: editingProduct.variety,
        }),
      });
      const res = await req.json();
      if (res.quantity) {
        let newProducts = [...products];
        const productIndex = products.findIndex(
          (item) => item._id === editingProduct._id
        );
        newProducts[productIndex] = res;
        setProducts(newProducts);
        setEditingProduct(initial_Product);
        setErrorField("");
        setIsEditOpened(false);
      }
    } catch (e) {
    }
  }

  async function handleDeleteProduct(e, id) {
    e.stopPropagation();
    const req = await fetch(`${API_URL}/products/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        token: `Bearer ${token}`,
      },
    });
    const res = await req.json();
    if (res === true) {
      const newProducts = products.filter((product) => product._id !== id);
      setProducts(newProducts);
    }
  }

  return (
    <>
      <Slide direction="right" in={isOpen} unmountOnExit mountOnEnter>
        <div className={`catalogue boxContent`}>
          <h1>Gestion du catalogue</h1>
          <div
            className={`catalogue_list ${
              isCreateOpened || isEditOpened ? "inactive" : ""
            }`}
          >
            <div className="catalogue_list_inset">
              {products.map((product, key) => (
                <div
                  ref={containerRef}
                  className="catalogue_item"
                  onClick={() => {
                    setEditingProduct(product);
                    setIsEditOpened(!isEditOpened);
                    setIsCreateOpened(false);
                  }}
                >
                  <div className="left">
                    <img
                      src={`/products_images/${product.product_ID.name}.jpg`}
                      alt=""
                    />
                  </div>
                  <div className="right">
                    <h3>{product.product_ID.name}</h3>
                    <p>
                      {product.variety.map((item, key) => (
                        <span key={key}>
                          {item}
                          {key === product.variety.length - 1 ? "" : ", "}
                        </span>
                      ))}
                    </p>
                    <div className="icon">
                      <IconButton>
                        <EditIcon color="primary" />{" "}
                      </IconButton>
                      <IconButton
                        onClick={(e) => handleDeleteProduct(e, product._id)}
                      >
                        <DeleteIcon color="warning" />{" "}
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Dropdown_Catalogue
            product={isCreateOpened ? creatingProduct : editingProduct}
            isOpened={isCreateOpened ? isCreateOpened : isEditOpened}
            setProduct={isCreateOpened ? setCreatingProduct : setEditingProduct}
            setIsOpened={isCreateOpened ? setIsCreateOpened : setIsEditOpened}
            initial_Product={initial_Product}
          />

          <span className="boxContent_closeIcon">
            <p>
              <span onClick={onCallback} className="left">
                Mon compte
              </span>{" "}
              <ArrowRightIcon /> <span className="right">Catalogue</span>
            </p>
            <CloseIcon onClick={onCallback} />
          </span>
          <div className="addButton">
            {isCreateOpened ? (
              <Button
                onCallback={(e) => handleSaveCreatingProduct()}
                color="green"
                size="medium"
                value="Valider"
                startIcon={null}
                classname={""}
              />
            ) : isEditOpened ? (
              <Button
                onCallback={(e) => handleSaveEditingProduct()}
                color="green"
                size="medium"
                value="Valider"
                startIcon={null}
                classname={""}
              />
            ) : (
              <Button
                onCallback={(e) => setIsCreateOpened((r) => !r)}
                color="green"
                size="medium"
                value="Ajouter un produit"
                startIcon={<AddIcon />}
                classname={""}
              />
            )}

            <Slide direction="up" in={errorField === "product"}>
              <div className="addButton_error">Merci de choisir un produit</div>
            </Slide>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default Catalogue;
