import "../styles/Home.scss";
import Button from "./Button";

const Welcome = ({ onCallback }) => {
  return (
    <div onClick={onCallback} className="welcomeBox_container">
      <div onClick={(e) => e.stopPropagation()} className="welcomeBox">
        <h1>
          Bienvenue sur <span>Petite Carotte</span>{" "}
        </h1>
        <p>
          Trouvez des fruits et légumes de maraichers{" "}
          <span>près de chez vous !</span>
        </p>
        <p className="desc">
          Petite Carotte est une plateforme entièrement gratuite favorisant le
          commerce de denrées locales entre particuliers ou maraichers.
        </p>

        <Button
          onCallback={onCallback}
          color="green"
          size="medium"
          value="C'est parti"
          startIcon={null}
          classname={"button"}
        />
      </div>
    </div>
  );
};

export default Welcome;
