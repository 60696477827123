import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../utils/context";
import { format_capitalize } from "../utils/format";
// import { useAndGetContext } from "../assets/context";

const GardensList = ({
  hoveredGardenInList,
  setHoveredGardenInList,
  callBackUserGarden,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { API_URL, user } = useContext(AuthContext);

  const [windowsWidth, setWindowsWidth] = useState(window.innerWidth);

  const [users, setUsers] = useState([]);

  const isMobile = () => windowsWidth < 580;
  const handleResize = (width) => setWindowsWidth(width);

  useEffect(() => {
    const newUsers = user
      ? props.visibleUsers.filter((item) => item._id !== user._id)
      : props.visibleUsers;
    setUsers(newUsers);
  }, [props.visibleUsers, user]);

  useEffect(() => {
    window.addEventListener("resize", (e) => handleResize(window.innerWidth));
  });

  function handleHoverItem(gardenID) {
    setHoveredGardenInList(gardenID);
  }

  return (
    <>
      <div className={`gardensList boxContent`}>
        <span className="boxContent_closeIcon">
          <p>
            <span className="left">
              <span>{users.length}</span> jardin
              {users.length > 1 ? "s" : ""} trouvé{users.length > 1 ? "s" : ""}
            </span>
          </p>
        </span>
        <div className="gardensList_content">
          <h1>Jardins dans cette zone</h1>
          {/* <div className="gardensList_filters">
            <div className="product">
              <label>Produit: </label> <span>Tous</span>
            </div>
            <div className="type">
              <label>Type d'agriculture: </label> <span>Tous</span>
            </div>
          </div> */}
          <div className="gardensList_list">
            {users.map((user, key) => {
              return (
                <div
                  key={key}
                  onClick={() => callBackUserGarden(user._id)}
                  onMouseEnter={() => handleHoverItem(user._id)}
                  onMouseLeave={() => handleHoverItem("")}
                  className={`${
                    hoveredGardenInList === user._id ? "hovered" : ""
                  } gardensList_item`}
                >
                  <h3>{format_capitalize(user.garden_name)}</h3>
                  <p>
                    {user.type === "particulier" ? "Particulier" : "Maraicher"}{" "}
                    -{" "}
                    {user.method === "tradi"
                      ? "Agriculture conventionnelle"
                      : user.method === "perma"
                      ? "Permaculture"
                      : user.method === "bio" && "Agriculture biologique"}{" "}
                    - {user.productsCount} produit
                    {user.productsCount > 1 ? "s" : ""}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default GardensList;
