import React, { useState, useEffect, useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
// import { useFirebase } from "../assets/base-context";
import TextField from "@mui/material/TextField";
// import { useAndGetContext } from "../assets/context";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import SortIcon from "@mui/icons-material/Sort";
import { AuthContext } from "../utils/context";
// import { BaseProducts } from "../data/BaseProducts";

const Filters = () => {
  const { API_URL, setProductIdFilter, setMethodFilter } =
    useContext(AuthContext);

  const [baseProducts, setBaseProducts] = useState([]);
  const [currentSearchedProduct, setCurrentSearchedProduct] = useState(null);

  const [filteredMethod, setFilteredMethod] = useState("all");
  const [isOpen, setIsOpen] = useState(false);

  // const firebase = useFirebase();
  // const Firestore = firebase.firestore();
  // const DBproductsRef = Firestore.collection("products");

  const [windowsWidth, setWindowsWidth] = useState(window.innerWidth);

  const isMobile = () => windowsWidth < 580;
  const handleResize = (width) => setWindowsWidth(width);

  useEffect(() => {
    fetch(`${API_URL}/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    }).then((res) =>
      res.json().then((res) => {
        const sortedBaseProducts = res.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setBaseProducts(sortedBaseProducts);
      })
    );
  }, []);

  useEffect(() => {
    window.addEventListener("resize", (e) => handleResize(window.innerWidth));
  });

  async function handleProductChanged(event, newValue) {
    setCurrentSearchedProduct(newValue);
    setProductIdFilter(newValue ? newValue._id : "")
  }

  const handleMethodChanged = (event) => {
    setFilteredMethod(event.target.value);
    setMethodFilter(event.target.value);
  };

  const resetFilters = () => {
    setProductIdFilter("")
    setMethodFilter("all")
    setIsOpen(false)
  }

  return (
    <div
      onClick={() => isOpen === false && setIsOpen(true)}
      className={`${isOpen ? "active" : ""} filterHeader`}
    >
      <div className="filterHeader_right">
        <h3>
          <SortIcon />
          {isMobile() && isOpen ? "Filtrez les résultats" : "Filtres"}
        </h3>
        <FormControl sx={{ m: 1 }} fullWidth>
          <Autocomplete
            color="white"
            placeholder="Produit"
            options={baseProducts}
            getOptionLabel={(option) => option.name}
            value={currentSearchedProduct}
            id="clear-on-escape"
            clearOnEscape
            renderInput={(params) => <TextField {...params} label="Produit" />}
            onChange={handleProductChanged}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Type d'agriculture
          </InputLabel>
          <Select
            native
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filteredMethod}
            defaultValue={filteredMethod}
            label="Type d'agriculture"
            onChange={handleMethodChanged}
          >
            <option value={"all"}>Tous</option>
            <option value={"tradi"}>Agriculture conventionnelle</option>
            <option value={"bio"}>Agriculture biologique</option>
            <option value={"perma"}>Permaculture</option>
          </Select>
        </FormControl>
        <span onClick={() => resetFilters()}>Réinitialiser les filtres</span>
      </div>
    </div>
  );
};

export default Filters;
