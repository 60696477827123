import React, { useState, useEffect, useContext } from "react";
import Map from "../components/Map";
import "../styles/Login.scss";
import Button from "../components/Button";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { AuthContext } from "../utils/context";
import emailjs from "@emailjs/browser"

// import { useAndGetContext } from "../assets/context";

const Subscribe = ({
  onCallback,
  onOpenLogin,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("particulier");
  const [address, setAddress] = useState(null);
  const [googleAdressObject, setGoogleAdressObject] = useState(null);
  const [error, setError] = useState("");
  const [isSubscribeValidated, setIsSubscribeValidated] = useState(false);

  const { API_URL, user, setUser } = useContext(AuthContext);

  useEffect(() => {});

  const handleSubscribe = async () => {

    if(/\S+@\S+\.\S+/.test(email) === false) {
      return setError("Votre email est invalide")
    }
    if(password.length < 8) {
      return setError("Votre mot de passe doit contenir 8 caractères minimum.")
    }

    const data = {
      email: email,
      password: password,
      garden_name: name,
      address: address,
      type: type,
    };
    const req = await fetch(`${API_URL}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });

    const res = await req.json();
     if (res.keyPattern) {
      if (res.keyPattern.email) setError("Cet email est déjà utilisé");
      else if (res.keyPattern.nickname) setError("Ce pseudo est déjà utilisé");
    }
    
    else {
      setError("");
      setEmail("");
      setPassword("");
      setName("")
      setIsSubscribeValidated(true);
      emailjs.send('service_yhg70n5', 'template_x3lwfbz', {link: `${process.env.REACT_APP_WEBSITE_PATH}/confirm?token=${res}`, to_email: email}, 'i1nCiWR2gkPaSVIhn')
      .then((result) => {
        
      }, (error) => {
          console.log(error);
      });
    }
  };

  const handleSelectChange = (event) => {
    setType(event.target.value);
  };

  function handleAdressChanged(val) {
    geocodeByPlaceId(val.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setAddress({ label: val.label, location: { lat, lng } });
        setGoogleAdressObject(val);
      });
  }

  return (
    <div onClick={onCallback} className="loginBox_container">
      <div onClick={(e) => e.stopPropagation()} className="loginBox">
        {!isSubscribeValidated ? (
          <>
            <h1>Inscrivez-vous</h1>
            <span className="loginBox_closeIcon">
              <CloseIcon onClick={onCallback} />
            </span>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "100%", marginBottom: "15px" },
                "& .MuiOutlinedInput-root": { borderRadius: "20px" },
              }}
              noValidate
              autoComplete="on"
            >
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="outlined-required"
                label="Email"
                type="email"
                error={error === "email"}
              />
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="outlined-password-input"
                label="Mot de passe"
                type="password"
                autoComplete="current-password"
                fullWidth
                error={error === "password"}
              />
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="outlined-password-input"
                label="Nom de votre jardin"
                autoComplete="current-password"
                fullWidth
                error={error === "name"}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  native
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  defaultValue={type}
                  label="Type"
                  onChange={handleSelectChange}
                >
                  <option value={"jardinier"}>Jardinier</option>
                  <option value={"maraicher"}>Maraicher</option>
                </Select>
              </FormControl>
              <GooglePlacesAutocomplete
                autocompletionRequest={{
                  bounds: [
                    { lat: 50, lng: 50 },
                    { lat: 100, lng: 100 },
                  ],
                  types: ["address"],
                  componentRestrictions: {
                    country: ["fr"],
                  },
                }}
                apiOptions={{
                  language: "fr",
                  region: "fr",
                }}
                selectProps={{
                  noOptionsMessage: () => "Tapez quelque chose ...",
                  placeholder: "L'adresse de votre jardin",
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      color: "blue",
                    }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "20px",
                      padding: "10px 14px",
                      cursor: "text",
                    }),
                    container: (provided) => ({
                      ...provided,
                      marginTop: "15px",
                      marginBottom: "15px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: "20px",
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    Svg: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: "1rem",
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
                    }),
                  },
                  value: googleAdressObject,
                  onChange: handleAdressChanged,
                }}
              />
              <p>Cette adresse ne sera pas visible pour le moment.</p>
            </Box>
            {error.length > 0 && (
              <div className="loginBox_errorMessage">
                {error}
              </div>
            )}
            <Button
              color="green"
              size="medium"
              value="Valider"
              onCallback={() => handleSubscribe()}
              startIcon={null}
              classname={""}
            />
            <p>Déjà inscrit ?</p>
            <a onClick={onOpenLogin}>Connectez-vous</a>
          </>
        ) : (
          <p style={{marginBottom:"15px", marginTop:"15px"}}>
            Merci pour votre inscription, vérifiez vos emails pour valider votre
            compte.
          </p>
        )}
      </div>
    </div>
  );
};

export default Subscribe;
