import React, { useState, useEffect, useContext } from "react";
import Map from "../components/Map";
import "../styles/Login.scss";
import Button from "../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AuthContext } from "../utils/context";

// import { useAndGetContext } from "../assets/context";

const Login = ({ onCallback, onOpenSubscribe }) => {
  const { API_URL, signIn } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // const { handleLogIn } = useAndGetContext();

  useEffect(() => {
    setError("");
  }, [email, password]);

  const handleLoginClicked = () => {
    const data = { email: email, password: password };
    signIn(data).then((res) => {
      if (res === "wrong password") setError("Mot de passe incorrects");
      else if (res === "wrong credentials") setError("Identifiants incorrects");
      else if (res === "Email not verified")
        setError("Vous devez avoir validé votre email pour vous connecter.");
      else if (res !== true) setError("Une erreur est survenue, réessayez");
      else if (res === true) onCallback();
    });
  };

  const handleFormValidated = (e) => {
    e.preventDefault();
    handleLoginClicked();
    return;
  };

  return (
    <div onClick={onCallback} className="loginBox_container">
      <div onClick={(e) => e.stopPropagation()} className="loginBox">
        <h1>Connectez-vous</h1>
        <span className="loginBox_closeIcon">
          <CloseIcon onClick={onCallback} />
        </span>
        <Box
          onSubmit={(e) => handleFormValidated(e)}
          component="form"
          sx={{
            "& .MuiTextField-root": { width: "100%", marginBottom: "15px" },
            "& .MuiOutlinedInput-root": { borderRadius: "20px" },
          }}
          noValidate
          autoComplete="on"
        >
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="outlined-required"
            type="email"
            label="Email"
            error={error === "email"}
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="outlined-password-input"
            label="Mot de passe"
            type="password"
            fullWidth
            error={error === "password"}
          />
          {error.length > 0 && (
            <div className="loginBox_errorMessage">{error}</div>
          )}
          <Button
            color="green"
            size="medium"
            value="Se connecter"
            onCallback={() => handleLoginClicked()}
            startIcon={null}
            classname={""}
          />
        </Box>
        <p>Pas encore inscrit ?</p>
        <a onClick={onOpenSubscribe}>Inscrivez-vous</a>
      </div>
    </div>
  );
};

export default Login;
