import React from "react";
import Header from "../components/Header";
import "../styles/FAQ.scss";

const FAQ = () => {
  return (
    <div className="faq_container">
      <h1>Foire aux questions</h1>

      <Header />
      <div className="faq_box">
        <div className="item">
          <h3>C'est quoi Petite-carotte.com ?</h3>
          <p>
            Nous tentons de créer une plateforme entièrement libre et gratuite,
            pour permettre à tous de trouver des fruits et légumes directement
            chez les particuliers ou maraichers près de chez eux. <br />A
            l'inverse, si vous avez des fruits & légumes à proposer, vous pouvez
            créer un "jardin" sur la carte intéractive en vous inscrivant. <br/>Vous
            serez ensuite visible des utilisateurs cherchant dans votre zone.
          </p>
        </div>
        <div className="item">
          <h3>
            Comment peut-on communiquer avec les utilisateurs ou "jardins" ?
          </h3>
          <p>
            Pour le moment, uniquement par téléphone. 
            <br/>Les "jardins" peuvent aussi afficher
            leur adresses précise pour vous permettre de vous y rendre sans
            contact préalable.
            <br/>Cependant il est conseillé de prendre contact par téléphone avant tout.
          </p>
        </div>
        <div className="item">
          <h3>
            Je peux payer directement sur la plateforme pour commander mes légumes ?
          </h3>
          <p>
            Absolument pas. Nous mettons en relation les ménages et les petits producteurs. La suite se passe entre vous.
          </p>
        </div>
        <div className="item">
          <h3>
            Pourquoi il n'y a aucun jardin autour de chez moi ?
          </h3>
          <p>
            La plateforme est très récente, nous vous invitons à vous inscrire et à y proposer vos productions même si la quantité est ridicule. Plus il y aura de jardins, plus il y aura de visiteurs. :)
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
