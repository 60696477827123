import logo from "./logo.svg";
import "./App.scss";
import { AuthProvider } from "./utils/context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import Home from "./pages/Home";
import Confirm_Email from "./pages/Confirm_Email";
import FAQ from "./pages/FAQ";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute>
                  <Home />
                </PublicRoute>
              }
            />

            <Route path="/confirm" 
              element={
                <PublicRoute>
                  <Confirm_Email />
                </PublicRoute>
              }>
            </Route>
            
            <Route path="/faq" 
              element={
                <PublicRoute>
                  <FAQ />
                </PublicRoute>
              }>
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
