import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [productIdFilter, setProductIdFilter] = useState("")
  const [methodFilter, setMethodFilter] = useState("all")

  // const API_URL = "http://localhost:5000/api";
  const API_URL = "https://petite-carotte.herokuapp.com/api"

  const token = localStorage.getItem("auth");

  useEffect(() => {
    function fetchAuth() {
      if (!token) {
        setUser(null);
        setTimeout(() => {
          setLoading(false);
        }, 150);
        return;
      }

      fetch(`${API_URL}/auth/isAuth`, {
        method: "POST",
        headers: {
          token: `Bearer ${token}`,
          "Content-Type": "application/json;charset=utf-8",
        },
      }).then((res) => {
        res.json().then((res) => {
          if (res.email) setUser(res);
          else setUser(null);
          setTimeout(() => {
            setLoading(false);
          }, 150);
        });
      });
    }
    fetchAuth();
  }, []);

  const signOut = () => {
    localStorage.removeItem("auth");
    setUser(null);
  };

  const signIn = async (data) => {
    const req = await fetch(`${API_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
    const res = await req.json();
    if (res.token) {
      localStorage.setItem("auth", res.token);
      const { password, token, ...others } = res;
      setUser(others);
      return true;
    } else return res;
  };

  const updateUser = async (data) => {
    const token = localStorage.getItem("auth");
    const req = await fetch(`${API_URL}/users/${user._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        token: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const res = await req.json();
    if (res.firstname) {
      setUser(res);
      return true;
    } else return res;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        signOut,
        signIn,
        updateUser,
        API_URL,
        productIdFilter,
        setProductIdFilter,
        methodFilter,
        setMethodFilter
      }}
    >
      {!loading ? (
        children
      ) : (
        <div
          style={{
            position: "fixed",
            left: "0",
            top: "0",
            height: "100vh",
            width: "100%",
            zIndex: "99",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </div>
      )}
    </AuthContext.Provider>
  );
};
