import React, { useState, useEffect,useContext } from "react";
import "../styles/Components.scss";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import GardenConfig from "./GardenConfig";
// import { useAndGetContext } from "../assets/context";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Catalogue from "./Catalogue";
import { AuthContext } from "../utils/context";

const MyGarden = ({ onCallback, isOpen }) => {
  const [isGardenConfigOpen, setIsGardenConfigOpen] = useState(false);
  const [isCatalogueOpen, setIsCatalogueOpen] = useState(false);
  
  const { API_URL, user, signOut } = useContext(AuthContext);

  useEffect(() => {
    setIsCatalogueOpen(false);
    setIsGardenConfigOpen(false);
  }, [isOpen]);

  return (
    <>
      <Slide direction="right" in={isOpen} unmountOnExit mountOnEnter>
        <div className={`myGarden boxContent`}>
          <span className="boxContent_closeIcon">
            <p>
              <span className="left">Mon compte</span>
            </p>
            <CloseIcon onClick={onCallback} />
          </span>
          <div className="myGarden_content">
            <h1>Gestion du compte</h1>
            <div className="myGarden_content_menu">
              <ul>
                <li onClick={() => setIsCatalogueOpen(true)}>Mon catalogue</li>
                <li onClick={() => setIsGardenConfigOpen(true)}>
                  Mon jardin
                  <p>
                    Statut:{" "}
                    <span className={`${user.status === "opened" || user.status === "hidden" ? "opened" : "closed"}`}>
                      {user.status === "opened" || user.status === "hidden" ? "Ouvert" : "Fermé"}
                    </span>
                  </p>
                </li>
                {/* <li onClick={() => setIsGardenConfigOpen(true)}>Paramètres</li> */}
                <li onClick={() => signOut()}>Déconnexion</li>
              </ul>
            </div>
          </div>
        </div>
      </Slide>
      {isOpen && (
        <>
          <GardenConfig
            isOpen={isGardenConfigOpen}
            onCallback={() => setIsGardenConfigOpen(false)}
          />
          <Catalogue
            isOpen={isCatalogueOpen}
            onCallback={() => setIsCatalogueOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default MyGarden;
