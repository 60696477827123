import React, { useState, useEffect } from "react";
import "../styles/Components.scss";

function Button({ value, size, color, startIcon, classname, onCallback }) {
  return (
    <div className={`customButton`}>
      <button className={`${size} ${color} ${classname}`} onClick={onCallback}>
        <span>
          {startIcon !== null && startIcon}
          {value}
        </span>
      </button>
    </div>
  );
}

export default Button;
