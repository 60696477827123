import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import SortIcon from "@mui/icons-material/Sort";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";

const CitySearch = ({
  callbackCity,
  city_search_google_address,
  setcity_search_google_address,
}) => {
  const [windowsWidth, setWindowsWidth] = useState(window.innerWidth);

  const isMobile = () => windowsWidth < 700;
  const handleResize = (width) => setWindowsWidth(width);

  useEffect(() => {}, []);

  useEffect(() => {
    window.addEventListener("resize", (e) => handleResize(window.innerWidth));
  });

  function handleCityChanged(val) {
    geocodeByPlaceId(val.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const formatedAddress = { label: val.label, location: { lat, lng } };
        setcity_search_google_address(val);
        callbackCity(formatedAddress);
      });
  }

  return (
    <div className="paramsMapBox">
      <div className="paramsMapBox_content">
        <h3>
          <SearchIcon />
        </h3>
        <FormControl sx={{ m: 0 }} fullWidth>
          <GooglePlacesAutocomplete
            autocompletionRequest={{
              bounds: [
                { lat: 50, lng: 50 },
                { lat: 100, lng: 100 },
              ],
              types: ["(regions)"],
              componentRestrictions: {
                country: ["fr"],
              },
            }}
            apiOptions={{
              language: "fr",
              region: "fr",
            }}
            selectProps={{
              noOptionsMessage: () => "Aucun résultat",
              placeholder: "Rechercher une ville",
              styles: {
                option: (provided) => ({
                  ...provided,
                  borderBottom: "1px dotted grey",
                  padding: 20,
                }),
                input: (provided) => ({
                  ...provided,
                  border: "none",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "20px",
                  padding: "10px 5px",
                  cursor: "text",
                  border: "none",
                  boxShadow: "none",
                }),
                container: (provided) => ({
                  ...provided,
                  marginBottom: "0",
                  border: "none",
                }),
                menu: (provided) => ({
                  ...provided,
                  borderRadius: isMobile() ? "0" : "10px",
                  zIndex: "9999",
                  border: "none",
                  width: isMobile() ? "calc(100% + 97px)" : "500px",
                  height: "auto",
                  left: "-72px",
                  bottom: "50px",
                  top: "inherit",
                  boxShadow: "none",
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                Svg: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: "1rem",
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
                }),
                indicator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              },
              value: city_search_google_address,
              onChange: handleCityChanged,
            }}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default CitySearch;
