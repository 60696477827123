import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../utils/context";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../styles/Login.scss";

// import { useAndGetContext } from "../assets/context";

const Confirm_Email = () => {
  const { API_URL } = useContext(AuthContext);

  const [isconfirmed, setisconfirmed] = useState(null);
  const [confirm_message, setconfirm_message] = useState(null);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token")
  const history = useNavigate();

  useEffect(() => {
    if(!token) return history("/")
    fetch(`${API_URL}/auth/verify_email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({ token: token }),
    }).then((res) =>
      res.json().then((res) => {
        if (res === true) setisconfirmed(true);
        else if (res === "already done") {
          setconfirm_message("Votre email a déjà été confirmé.");
          setisconfirmed(false);
        } else {
            history("/")
        }
      })
    );
  }, []);

  return (
    <div className="confirm_container">
      <div className="confirm_box">
        {isconfirmed === true
          ? "Merci, votre email est maintenant confirmé."
          : confirm_message}
        <p onClick={() => history(`/`)}>Retour sur le site</p>
      </div>
    </div>
  );
};

export default Confirm_Email;
