import React, { useState, useEffect, useContext } from "react";
import "../styles/Components.scss";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { useAndGetContext } from "../assets/context";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "../components/Button";
import { AuthContext } from "../utils/context";

// import { useFirebase } from "../assets/base-context"

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#4cb051",
    "&:hover": {
      backgroundColor: alpha("#4cb051", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#4cb051",
  },
}));

const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "rgba(55, 66, 216, 0.6)",
    "&:hover": {
      backgroundColor: alpha(
        "rgba(55, 66, 216, 0.6)",
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "rgba(55, 66, 216, 0.6)",
  },
}));

const GardenConfig = ({ onCallback, isOpen }) => {
  const token = localStorage.getItem("auth");
  const [googleAdressObject, setGoogleAdressObject] = useState(null);
  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);
  const [address, setAddress] = useState(null);
  const [errorAddress, setErrorAddress] = useState(false);
  const [type, setType] = useState("particulier");
  const [method, setMethod] = useState("tradi");
  const [is_hidden, setIs_hidden] = useState(false);
  const [is_opened, setIs_opened] = useState(false);
  const [validField, setValidField] = useState("");
  const [errorField, setErrorField] = useState("");

  const { API_URL, user, setUser } = useContext(AuthContext);

  // const firebase = useFirebase()
  // const Firestore = firebase.firestore()
  // const Firebase = firebase.firebase()

  // const GeoFirestore = firebase.geoFirestore()
  // const geocollection = GeoFirestore.collection('users');

  useEffect(() => {
    let newGoogleAddress = null;
    if (user.address !== null) {
      newGoogleAddress = {
        label: user.address.label,
        value: { description: user.address.label },
      };
      setGoogleAdressObject(newGoogleAddress);
    }
    setAddress(user.address);
    setName(user.garden_name);
    setType(user.type);
    setPhone(user.phone);
    user.method && setMethod(user.method);
    if (user.status === "opened" || user.status === "hidden")
      setIs_opened(true);
    if (user.status === "hidden") setIs_hidden(true);
    if (user.status === "closed") {
      setIs_opened(false);
      setIs_hidden(true);
    }
  }, [isOpen]);

  useEffect(() => {
    setErrorName(false);
    if (name.length < 6 || name.length > 40) {
      setIs_opened(false);
      if (name !== user.garden_name && name !== "") {
        setErrorName(true);
      }
    }
  }, [name]);

  useEffect(() => {
    setErrorPhone(false);
    if (phone.length !== 10) {
      setIs_opened(false);
      if (phone !== user.phone && phone !== "") {
        setErrorPhone(true);
      }
    }
  }, [phone]);

  useEffect(() => {
    if (is_opened === false) setIs_hidden(true);
  }, [is_opened]);

  function handleAdressChanged(val) {
    setErrorAddress(false);
    geocodeByPlaceId(val.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const formatedAddress = { label: val.label, location: { lat, lng } };
        setGoogleAdressObject(val);
        setAddress(formatedAddress);
      });
  }

  const handleTypeChanged = (event) => {
    setType(event.target.value);
  };

  const handleMethodChanged = (event) => {
    setMethod(event.target.value);
  };

  function handleIs_opened(val) {
    if (val === true) {
      if (!user.email_verified) {
        setIs_opened(false);
        setErrorField("verified");
        setTimeout(() => {
          setErrorField("");
        }, 3000);
      } else if (name.length < 6 || name.length > 40) {
        setErrorField("name_format");
        setTimeout(() => {
          setErrorField("");
        }, 3000);
        setErrorName(true);
      } else if (address === null) {
        setErrorAddress(true);
        setErrorField("address");
        setTimeout(() => {
          setErrorField("");
        }, 3000);
        return;
      } else if (phone.length !== 10) {
        setErrorPhone(true);
        setErrorField("phone");
        setTimeout(() => {
          setErrorField("");
        }, 3000);
        return;
      } else {
        setIs_opened(true);
      }
    } else setIs_opened(false);
  }

  async function handleSendChanges(e) {
    setErrorName(false);
    setErrorPhone(false);
    e.preventDefault();
    if ((name.length < 6 || name.length > 40) && name !== "") {
      setErrorName(true);
      setErrorField("name_format");
      setTimeout(() => {
        setErrorField("");
      }, 3000);
      setErrorName(true);
      return;
    } else if (phone.length !== 10 && phone !== "") {
      setErrorPhone(true);
      setErrorField("phone");
      setTimeout(() => {
        setErrorField("");
      }, 3000);
      return;
    } else {
      const lat = address ? address?.location.lat : 0;
      const lng = address ? address?.location.lng : 0;
      try {
        const req = await fetch(`${API_URL}/users/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            token: `Bearer ${token}`,
          },
          body: JSON.stringify({
            garden_name: name,
            phone,
            address,
            type,
            method,
            status:
              is_hidden && is_opened
                ? "hidden"
                : !is_hidden && is_opened
                ? "opened"
                : "closed",
          }),
        });
        const res = await req.json();
        if (res.email) {
          setValidField("send");
          setUser(res);
          setTimeout(() => {
            setValidField("");
          }, 3000);
        }
      } catch (e) {
        setErrorField("send");
        setTimeout(() => {
          setErrorField("");
        }, 3000);
      }
    }
  }

  return (
    <Slide direction="right" in={isOpen} unmountOnExit mountOnEnter>
      <div className={`gardenConfig boxContent`}>
        <span className="boxContent_closeIcon">
          <p>
            <span onClick={onCallback} className="left">
              Mon compte
            </span>{" "}
            <ArrowRightIcon /> <span className="right">Mon jardin</span>
          </p>
          <CloseIcon onClick={onCallback} />
        </span>
        <div className="gardenConfig_content">
          <h1>Paramètres du jardin</h1>
          <Box
            className="content"
            component="form"
            sx={{
              "& .MuiTextField-root": { width: "100%", marginBottom: "15px" },
              "& .MuiOutlinedInput-root": { borderRadius: "20px" },
              "& .MuiFormControlLabel-root": { width: "100%" },
              "& .MuiFormControl-root.css-q8hpuo-MuiFormControl-root": {
                marginBottom: "20px",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="gardenConfig_content_garden">
              <h3>Le jardin</h3>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="outlined-required"
                label="Nom"
                type="email"
                defaultValue=""
                helperText="Le nom que verront les visiteurs"
                error={errorName}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  native
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  defaultValue={type}
                  label="Type"
                  onChange={handleTypeChanged}
                >
                  <option value={"particulier"}>Particulier</option>
                  <option value={"maraicher"}>Maraicher</option>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Méthode</InputLabel>
                <Select
                  native
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={method}
                  defaultValue={method}
                  label="Méthode"
                  onChange={handleMethodChanged}
                >
                  <option value={"tradi"}>Agriculture conventionnelle</option>
                  <option value={"bio"}>Agriculture biologique</option>
                  <option value={"perma"}>Permaculture</option>
                </Select>
              </FormControl>
            </div>
            <div className="gardenConfig_content_contact">
              <h3>Coordonnées</h3>
              <GooglePlacesAutocomplete
                autocompletionRequest={{
                  bounds: [
                    { lat: 50, lng: 50 },
                    { lat: 100, lng: 100 },
                  ],
                  types: ["address"],
                  componentRestrictions: {
                    country: ["fr"],
                  },
                }}
                apiOptions={{
                  language: "fr",
                  region: "fr",
                }}
                selectProps={{
                  noOptionsMessage: () => "Tapez quelque chose ...",
                  placeholder: "L'adresse de votre jardin",
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      color: "blue",
                    }),
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "20px",
                      padding: "10px 5px",
                      cursor: "text",
                    }),
                    container: (provided) => ({
                      ...provided,
                      marginBottom: "0",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: "20px",
                      zIndex: "9999",
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    Svg: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: "1rem",
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif;',
                    }),
                  },
                  value: googleAdressObject,
                  onChange: handleAdressChanged,
                }}
              />
              <p className="gardenConfig_content_autocompleteAdressHelpText">
                Si vous ne souhaitez pas que les visiteurs puissent vous
                localiser, désactivez l'option "Afficher l'adresse"
              </p>
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                id="outlined-required"
                label="Numéro de téléphone"
                type="phone"
                defaultValue=""
                helperText="Permettra aux visiteurs de vous contacter."
                error={errorPhone}
              />
            </div>
            <FormControlLabel
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "0",
              }}
              label="Activer mon jardin"
              labelPlacement="start"
              control={
                <GreenSwitch
                  checked={is_opened}
                  onChange={(e) => handleIs_opened(e.target.checked)}
                />
              }
            />
            <FormControlLabel
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "0",
              }}
              label="Afficher l'adresse"
              labelPlacement="start"
              control={
                <BlueSwitch
                  checked={!is_hidden}
                  onChange={(e) => setIs_hidden(!e.target.checked)}
                  disabled={is_opened !== true}
                />
              }
            />
          </Box>
          <div className="saveButton">
            <Button
              onCallback={(e) => handleSendChanges(e)}
              color="green"
              size="medium"
              value="Sauvegarder"
              startIcon={null}
              classname={""}
            />

            <Slide
              direction="up"
              in={validField === "send"}
              unmountOnExit
              mountOnEnter
            >
              <div className="saveButton_confirm">
                Paramères sauvegardés avec succés.
              </div>
            </Slide>

            <Slide
              direction="up"
              in={errorField === "send"}
              unmountOnExit
              mountOnEnter
            >
              <div className="saveButton_error">
                Une erreur inconnue est survenue, merci de d'essayer à nouveau.
              </div>
            </Slide>
            <Slide
              direction="up"
              in={errorField === "verified"}
              unmountOnExit
              mountOnEnter
            >
              <div className="saveButton_error">
                Vous devez avoir validé votre adresse email pour activer votre
                jardin. Vérifiez vos emails.
              </div>
            </Slide>
            <Slide
              direction="up"
              in={errorField === "name_format"}
              unmountOnExit
              mountOnEnter
            >
              <div className="saveButton_error">
                Le nom de votre jardin doit contenir entre 6 et 40 caractères.
              </div>
            </Slide>
            <Slide
              direction="up"
              in={errorField === "address"}
              unmountOnExit
              mountOnEnter
            >
              <div className="saveButton_error">
                Vous devez avoir renseigné une adresse pour activer votre
                jardin.
              </div>
            </Slide>
            <Slide
              direction="up"
              in={errorField === "phone"}
              unmountOnExit
              mountOnEnter
            >
              <div className="saveButton_error">
                Merci de renseigner un numéro de téléphone valide.
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default GardenConfig;
