import React, { useEffect, useContext } from "react";
import { Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context";

const PublicRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  // return !user ? children : <Navigate to="/" />;
  return children
};

export default PublicRoute;
