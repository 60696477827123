import React, { useState, useEffect, useContext } from "react";
import GoogleMapReact from "google-map-react";
import "../styles/Map.scss";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// import { useFirebase } from "../assets/base-context";
import Marker from "./Marker";
import { AuthContext } from "../utils/context";
// import { useAndGetContext } from "../assets/context";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Map = ({
  userPosition,
  currentCenter,
  callBackMyGarden,
  callBackUserGarden,
  callbackList,
  currentUserGardenID,
  hoveredGardenInList,
  setHoveredGardenInList,
  setMapPosition,
  visibleUsers,
  setvisibleUsers,
}) => {
  const { API_URL, user, productIdFilter, methodFilter } =
    useContext(AuthContext);
  const token = localStorage.getItem("auth");

  const [defaultPosition, setDefaultPosition] = useState({
    latitude: 48.8534,
    longitude: 2.3488,
  });

  // const firebase = useFirebase();
  // const Firestore = firebase.firestore();
  // const Firebase = firebase.firebase();
  // const GeoFirestore = firebase.geoFirestore();
  const geocollection = [];

  const [isReady, setIsReady] = useState(false);
  const [currentZoom, setCurrentZoom] = useState(13);

  useEffect(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    handleMapMooved({
      zoom: currentZoom,
      center: { lat: currentCenter.latitude, lng: currentCenter.longitude },
    });
  }, [productIdFilter, methodFilter, user]);

  async function getUsersinScreen(center, km) {
    const req = await fetch(`${API_URL}/users/near_users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        token: `Bearer ${token}`,
      },
      body: JSON.stringify({
        lat: center.lat,
        lng: center.lng,
        max: km / 2,
        productIdFilter: productIdFilter,
        methodFilter: methodFilter
      }),
    });
    const res = await req.json();
    setvisibleUsers(res);
  }

  function convertZoomTokm(zoom) {
    return ((591657.49722 / Math.pow(2, zoom)) * 2) / 10;
  }

  function handleMapMooved(e) {
    setMapPosition({ latitude: e.center.lat, longitude: e.center.lng });
    const km = convertZoomTokm(e.zoom);
    setCurrentZoom(e.zoom);
    getUsersinScreen(e.center, km);
  }

  function handleUserGardenClicked(userID) {
    callBackUserGarden(userID);
  }

  function handleHoverItem(gardenID) {
    setHoveredGardenInList(gardenID);
  }

  return isReady ? (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({ map, maps }) => console.log(map, maps)}
        options={{
          minZoom: 7,
          maxZoom: 17,
          fullscreenControl: false,
        }}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
          language: "fr",
          region: "fr",
        }}
        defaultCenter={{
          lat: defaultPosition.latitude,
          lng: defaultPosition.longitude,
        }}
        center={{
          lat: currentCenter.latitude,
          lng: currentCenter.longitude,
        }}
        zoom={currentZoom}
        onChange={(e) => handleMapMooved(e)}
      >
        {visibleUsers
          .filter((item) => (user ? item._id !== user._id : item))
          .map((user, key) => {
            return (
              <Marker
                key={key}
                onMouseEnter={() => handleHoverItem(user._id)}
                onMouseLeave={() => handleHoverItem("")}
                hovered={hoveredGardenInList === user._id}
                active={currentUserGardenID === user._id}
                onClick={() => handleUserGardenClicked(user._id)}
                type="users"
                lat={user.address.location.lat}
                lng={user.address.location.lng}
                text="My Marker"
              />
            );
          })}
        {user && (
          <Marker
            onMouseEnter={null}
            onMouseLeave={null}
            hovered={false}
            active={false}
            onClick={callBackMyGarden}
            type="user"
            lat={user.address && user.address.location.lat}
            lng={user.address && user.address.location.lng}
            text="My Marker"
          />
        )}

        {/* {userPosition.latitude !== 0 && (
          <Marker
            onMouseEnter={null}
            onMouseLeave={null}
            hovered={false}
            active={false}
            onClick={null}
            type="geo"
            lat={userPosition.latitude}
            lng={userPosition.longitude}
            text="My Marker"
          />
        )} */}
      </GoogleMapReact>
    </div>
  ) : (
    <Box sx={{ display: "flex", height: "100%", alignItems: "center" }}>
      <CircularProgress style={{ margin: "0 auto" }} />
    </Box>
  );
};

export default Map;
