import React, { useState, useEffect, useContext } from "react";
import Map from "../components/Map";
import Login from "../components/Login";
import "../styles/Process.scss";
import Button from "../components/Button";
import Subscribe from "../components/Subscribe";
// import { useAndGetContext } from "../assets/context";
import MyGarden from "../components/MyGarden";
import UserGarden from "../components/UserGarden";
import Filters from "../components/Filters";
import CitySearch from "../components/CitySearch";
import PersonIcon from "@mui/icons-material/Person";
import GardensList from "../components/GardensList";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import SearchIcon from "@mui/icons-material/Search";
import publicIp from "public-ip";
import { AuthContext } from "../utils/context";
import { GoogleApiWrapper } from "google-maps-react";
import Welcome from "../components/Welcome";
import Header from "../components/Header";

const defaultPosition = {
  latitude: 48.8534,
  longitude: 2.3488,
};

const Home = () => {
  const { API_URL, user, setUser } = useContext(AuthContext);
  const welcome = localStorage.getItem("welcome");

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);
  const [isMyGardenOpen, setIsMyGardenOpen] = useState(false);
  const [isUserGardenOpen, setIsUserGardenOpen] = useState(false);
  const [isWelcomeClosed, setisWelcomeClosed] = useState(false);

  const [currentUserGardenID, setCurrentUserGardenID] = useState("");

  const [windowsWidth, setWindowsWidth] = useState(window.innerWidth);

  const [visibleUsers, setvisibleUsers] = useState([]);

  const [hoveredGardenInList, setHoveredGardenInList] = useState("");

  const [currentCenter, setCurrentCenter] = useState(defaultPosition);
  const [userPosition, setUserPosition] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [globalPosition, setGlobalPosition] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [mapPosition, setMapPosition] = useState(defaultPosition);

  const [city_search_google_address, setcity_search_google_address] =
    useState(null);

  const isMobile = () => windowsWidth < 580;
  const handleResize = (width) => setWindowsWidth(width);

  useEffect(() => {
    window.addEventListener("resize", (e) => handleResize(window.innerWidth));
  });

  useEffect(() => {
    if (welcome) {
      const item = JSON.parse(welcome);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(welcome);
        return null;
      }
      setisWelcomeClosed(true);
    }
  }, []);

  useEffect(() => {
    publicIp.v4().then((ip) => {
      fetch(`https://ipapi.co/${ip}/json/`)
        .then((res) => res.json())
        .then((res) => {
          setCurrentCenter({
            latitude: res.latitude,
            longitude: res.longitude,
          });
        });
    });
  }, []);

  useEffect(() => {
    if (userPosition.latitude !== 0) setCurrentCenter(userPosition);
    else if (globalPosition.latitude !== 0) setCurrentCenter(globalPosition);
  }, [userPosition, globalPosition]);

  function getUserPosition() {
    if (userPosition.latitude === 0) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setUserPosition(position.coords);
        },
        (err) => console.log(err),
        { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
      );

      setcity_search_google_address(null);
    } else {
      if (
        // Les valeurs des deux n'ont pas le meme nombre de decimales donc je fixed pour les comparer
        // Et je réduis à deux décimales pour ne pas etre trop fixe, du coup on peut bouger la map un peu avant que la condition soit remplie
        mapPosition.latitude.toFixed(2) === userPosition.latitude.toFixed(2) &&
        mapPosition.longitude.toFixed(2) === userPosition.longitude.toFixed(2)
      )
        return;
      setCurrentCenter(defaultPosition);
      setcity_search_google_address(null);
      setTimeout(() => {
        setCurrentCenter(userPosition);
      }, 50);
    }
  }

  function handleOpenUserGarden(userID) {
    if (isUserGardenOpen && userID !== currentUserGardenID) {
      setIsUserGardenOpen(false);
      setTimeout(() => {
        setCurrentUserGardenID(userID);
        setIsUserGardenOpen(true);
      }, 200);
    } else {
      setCurrentUserGardenID(userID);
      setIsMyGardenOpen(false);
      setIsUserGardenOpen(true);
    }
  }

  function handleOpenMyGarden() {
    setCurrentUserGardenID("");
    setIsMyGardenOpen(true);
    setIsUserGardenOpen(false);
  }

  function handleCloseUserGarden() {
    setCurrentUserGardenID("");
    setIsUserGardenOpen(false);
  }

  const handleCloseWelcome = () => {
    const item = {
      value: "welcome",
      expiry: new Date().getTime() + 60 * 60 * 24 * 1000,
    };
    localStorage.setItem("welcome", JSON.stringify(item));
    setisWelcomeClosed(true);
  };

  return (
    <div className="process">
     <Header handleOpenMyGarden={handleOpenMyGarden} setIsLoginOpen={setIsLoginOpen} user={user} type={"process"} />
      <Filters />
      <CitySearch
        callbackCity={(e) =>
          setCurrentCenter({
            latitude: e.location.lat,
            longitude: e.location.lng,
          })
        }
        city_search_google_address={city_search_google_address}
        setcity_search_google_address={setcity_search_google_address}
      />
      <div className="process_page">
        <div className="process_page_content">
          <Map
            setMapPosition={(pos) => setMapPosition(pos)}
            userPosition={userPosition}
            currentCenter={currentCenter}
            currentUserGardenID={currentUserGardenID}
            callBackMyGarden={() => handleOpenMyGarden()}
            callBackUserGarden={(userID) => handleOpenUserGarden(userID)}
            hoveredGardenInList={hoveredGardenInList}
            setHoveredGardenInList={(id) => setHoveredGardenInList(id)}
            setvisibleUsers={setvisibleUsers}
            visibleUsers={visibleUsers}
          />
          <div className="positionWidget">
            <Button
              color="white"
              size="small"
              value={""}
              onCallback={() => getUserPosition()}
              startIcon={<GpsFixedOutlinedIcon />}
              classname={""}
            />
            {/* {user.address && user.address.label.length > 0 && (
              <Button
                color="white"
                size="small"
                value={"Mon jardin"}
                onCallback={() => null}
                startIcon={<GpsFixedIcon />}
                classname={""}
              />
            )} */}
          </div>
        </div>
      </div>

      {windowsWidth > 1270 && (
        <GardensList
          hoveredGardenInList={hoveredGardenInList}
          setHoveredGardenInList={(id) => setHoveredGardenInList(id)}
          visibleUsers={visibleUsers}
          setvisibleUsers={setvisibleUsers}
          callBackUserGarden={(userID) => handleOpenUserGarden(userID)}
        />
      )}

      {user && (
        <MyGarden
          isOpen={isMyGardenOpen}
          onCallback={() => setIsMyGardenOpen(false)}
        />
      )}

      <UserGarden
        isOpen={isUserGardenOpen}
        userID={currentUserGardenID}
        onCallback={() => handleCloseUserGarden()}
      />

      {isLoginOpen && (
        <Login
          onOpenSubscribe={() => {
            setIsLoginOpen(false);
            setIsSubscribeOpen(true);
          }}
          onCallback={() => setIsLoginOpen(false)}
        />
      )}

      {!isWelcomeClosed && !user && (
        <Welcome onCallback={() => handleCloseWelcome()} />
      )}

      {isSubscribeOpen && (
        <Subscribe
          onOpenLogin={() => {
            setIsLoginOpen(true);
            setIsSubscribeOpen(false);
          }}
          onCallback={() => setIsSubscribeOpen(false)}
        />
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(Home);
