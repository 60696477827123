import React, { useState, useEffect } from "react";
import GrassIcon from '@mui/icons-material/Grass';
import HouseIcon from '@mui/icons-material/House';
import PlaceIcon from "@mui/icons-material/Place";

const Marker = ({ lat, lng, text, type, onClick, active, hovered, onMouseEnter, onMouseLeave }) => {


  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`mapMarker ${type} ${active ? "active" : ""} ${hovered ? "hovered" : ""}`}
      lat={lat}
      lng={lng}
      text={text}
      onClick={onClick}
    >
      {type === "users" ? <GrassIcon /> : type === "user" ? <HouseIcon /> : <PlaceIcon /> }
    </div>
  )
};

export default Marker;
