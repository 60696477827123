import React, { useState, useEffect, useContext } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../utils/context";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const Dropdown_Catalogue = ({
  product,
  isOpened,
  setIsOpened,
  setProduct,
  initial_Product,
}) => {
  const { API_URL } = useContext(AuthContext);

  const [baseProducts, setBaseProducts] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    }).then((res) =>
      res.json().then((res) => {
        const sortedBaseProducts = res.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setBaseProducts(sortedBaseProducts);
      })
    );
  }, []);

  function handleVarietyChanged(e, variety) {
    if (variety.length > 5) return;
    const newVariety = variety;

    setProduct({
      name: product.name,
      type: product.type,
      quantity: product.quantity,
      product_ID: product.product_ID,
      harvest_at: product.harvest_at,
      variety: newVariety,
      _id: product._id,
    });
  }

  async function handleProductChanged(event, newValue) {
    let newProduct = { ...product };

    if (newValue) {
      newProduct.name = newValue.name;
      newProduct.type = newValue.type;
      newProduct.product_ID = newValue._id;
      setProduct(newProduct);
    } else {
      setProduct(initial_Product);
    }
  }

  function handleQuantityChanged(event, child) {
    let newQuantity = event.target.value;

    let newProduct = { ...product };
    newProduct.quantity = newQuantity;
    setProduct(newProduct);
  }

  function handleHarvest_atChanged(date) {
    let newHarvest_at = date;

    let newProduct = { ...product };
    newProduct.harvest_at = newHarvest_at;
    setProduct(newProduct);
  }

  return (
    <div className={`catalogue_dropDown ${isOpened ? "active" : ""}`}>
      <h2>{product._id.length < 1 ? "Ajouter" : "Modifier"} un produit</h2>
      <div className="catalogue_dropDown_top">
        <FormControl sx={{ m: 1 }} fullWidth>
          <Autocomplete
            color="white"
            placeholder="Choisissez un produit"
            options={baseProducts}
            // Deux cas: celui ou le nom vient des options, et celui ou il vient du produit en train d'etre modifié.
            getOptionLabel={(option) =>
              option.name !== undefined ? option.name : option.product_ID.name
            }
            value={product}
            id="clear-on-escape"
            clearOnEscape
            renderInput={(params) => <TextField {...params} label="Produit" />}
            onChange={handleProductChanged}
            disabled={product._id.length > 1}
          />
        </FormControl>
        <FormControl sx={{ m: 1, maxWidth: "32%" }} fullWidth>
          <InputLabel id="demo-simple-select-label">Quantité</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={product.quantity}
            label="Quantité"
            onChange={handleQuantityChanged}
          >
            <MenuItem value={"0-10"}>1 à 10 kg</MenuItem>
            <MenuItem value={"10-50"}>10 à 50 kg</MenuItem>
            <MenuItem value={"50+"}>+ de 50 kg</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="catalogue_dropDown_bottom">
        <FormControl sx={{ m: 1 }} fullWidth>
          <Autocomplete
            multiple
            id="multiple-limit-tags"
            options={[].map(() => "")}
            freeSolo
            limitTags={1}
            autoSelect
            value={product.variety}
            onChange={handleVarietyChanged}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Variétés"
                placeholder="Variétés"
                helperText="Appuyez sur entré entre chaque variété"
              />
            )}
          />
        </FormControl>

        <FormControl sx={{ m: 1, maxWidth: "35%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Date de récolte"
                inputFormat="dd/MM/yyyy"
                value={product.harvest_at}
                onChange={handleHarvest_atChanged}
                renderInput={(params) => <TextField {...params} />}
                disabled={product._id.length > 1}
              />
            </Stack>
          </LocalizationProvider>
        </FormControl>
      </div>
      <span
        onClick={() => setIsOpened(false)}
        className="catalogue_dropDown_closeIcon"
      >
        <CloseIcon />
      </span>
    </div>
  );
};

export default Dropdown_Catalogue;
