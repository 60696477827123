import React, { useState, useEffect, useContext } from "react";
import "../styles/Components.scss";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
// import { useAndGetContext } from "../assets/context";
// import { useFirebase } from "../assets/base-context";
import PersonIcon from "@mui/icons-material/Person";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { AuthContext } from "../utils/context";
import { format_capitalize, format_since_date } from "../utils/format";

const initialUserGarden = {
  address: {
    label: "",
    location: {
      lat: 0,
      lng: 0,
    },
  },
  name: "",
  type: "",
  method: "tradi",
  updated_at: new Date(),
  phone: "",
  is_hidden: false,
  products: [],
};

const UserGarden = ({ onCallback, isOpen, userID }) => {
  const { API_URL } = useContext(AuthContext);

  const [userGarden, setUserGarden] = useState(initialUserGarden);
  const [userProducts, setuserProducts] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isOpen === true) setUserGarden(initialUserGarden);
    if (userID === "") return;
    setIsReady(false);

    fetch(`${API_URL}/users/${userID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    }).then((res) =>
      res.json().then((res) => {
        setUserGarden(res);
        setIsReady(true);
      })
    );

    fetch(`${API_URL}/products/${userID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    }).then((res) =>
      res.json().then((res) => {
        setuserProducts(res);
      })
    );

    // DBusersRef.doc(userID)
    //   .get()
    //   .then((doc) => {
    //     const data = doc.data();
    //     DBusersRef.doc(doc.id)
    //       .collection("products")
    //       .get()
    //       .then((res) => {
    //         let products = [];
    //         res.forEach((doc) => {
    //           const data = doc.data();
    //           const today = new Date();
    //           const harvestDate = new Date(data.harvest_at.seconds * 1000);
    //           const newDateField = Math.abs(today - harvestDate);
    //           const newData = {
    //             ...data,
    //             harvest_at: Math.floor(newDateField / 60 / 60 / 24 / 1000),
    //           };
    //           products.push(newData);
    //         });

    //         setUserGarden({
    //           address: {
    //             label: data.address.label,
    //             location: {
    //               lat: data.address.location.lat,
    //               lng: data.address.location.lng,
    //             },
    //           },
    //           name: data.name,
    //           type: data.type,
    //           method: data.method,
    //           updated_at: data.updated_at,
    //           phone: data.phone,
    //           is_hidden: data.is_hidden,
    //           products: products,
    //         });
    //         setIsReady(true);
    //       });
    //   });
  }, [isOpen, userID]);

  function formateDateSentence(days) {
    if (days === 0) return "aujourd'hui";
    else if (days === 1) return `il y a ${days} jour`;
    else return `il y a ${days} jours`;
  }

  function formateQuantitySentence(quantity) {
    if (quantity === "0-10") return "1 à 10 kg";
    else if (quantity === "10-50") return "10 à 50 kg";
    else return "Plus de 50 kg";
  }

  return (
    <Slide direction="right" in={isOpen} unmountOnExit mountOnEnter>
      <div className={`userGarden boxContent`}>
        {isReady ? (
          <>
            <span className="boxContent_closeIcon">
              <p>
                <span className="left"></span>
              </p>
              <CloseIcon onClick={onCallback} />
            </span>
            <div className="userGarden_content">
              <h1>{format_capitalize(userGarden.garden_name)}</h1>
              <div className="userGarden_content_listParams">
                <ul>
                  <li>
                    <PersonIcon />{" "}
                    {userGarden.type === "particulier"
                      ? "Particulier"
                      : "Maraicher"}
                  </li>
                  <li>
                    <BlurCircularIcon />
                    {""}
                    {userGarden.method === "tradi"
                      ? "Agriculture conventionnelle"
                      : userGarden.method === "perma"
                      ? "Permaculture"
                      : userGarden.method === "bio" && "Agriculture biologique"}
                  </li>
                  <li>
                    <PhoneAndroidIcon />{" "}
                    {userGarden?.phone.replace(/.{1,2}(?=(.{2})+$)/g, "$& ")}
                  </li>
                  <li>
                    <LocationOnIcon />{" "}
                    {userGarden?.status === "hidden"
                      ? "Adresse précise cachée"
                      : userGarden?.address.label}
                  </li>
                </ul>
              </div>
              <div className="userGarden_content_listProducts">
                {userProducts.map((item, key) => {
                  return (
                    <div key={key} className="catalogue_item">
                      <div className="left">
                        <img
                          src={`/products_images/${item.product_ID.name}.jpg`}
                          alt=""
                        />
                      </div>
                      <div className="right">
                        <div className="infos">
                          <h3>{item.product_ID.name}</h3>
                          <p>
                            {item.variety.map((variety, key) => (
                              <span key={key}>{`${variety}${
                                key === item.variety.length - 1 ? "" : ", "
                              }`}</span>
                            ))}
                          </p>
                        </div>
                        <div className="params">
                          <span>
                            <AccessTimeIcon />
                            <p>
                              {`Récolté ${format_since_date(item.harvest_at)}`}{" "}
                            </p>
                          </span>
                          <span>
                            <ProductionQuantityLimitsIcon />
                            <p>
                              {`${formateQuantitySentence(item.quantity)}`}{" "}
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <Box sx={{ display: "flex", height: "100%", alignItems: "center" }}>
            <CircularProgress style={{ margin: "0 auto" }} />
          </Box>
        )}
      </div>
    </Slide>
  );
};

export default UserGarden;
