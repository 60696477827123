const format_since_date = (date) => {
  const createdAt = new Date(date);
  const now = new Date();

  const days = Math.round(Math.ceil((now - createdAt) / 1000 / 60 / 60) / 24);
  const result =
    days === 0
      ? "Aujourd'hui"
      : days === 1
      ? "Hier"
      : days > 30
      ? `Il y a ${Math.round(days / 30)} mois`
      : `Il y a ${days} jour${days > 1 ? "s" : ""}`;

  return result;
};

const format_capitalize = (sentence) => {
  const first = sentence.charAt(0).toUpperCase();
  const rest = sentence.slice(1);
  return first + rest;
};

export { format_since_date, format_capitalize };
